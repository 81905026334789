.about_page {
  font-size: 22px;
}

.about_page h1 {
  font-size: 60px;
  margin: 0;
}

.about_page h2 {
  text-align: center;
  font-size: 40px;
}

.about {
  background-color: #efefef;
  padding: 5rem;
}

.expect {
  background-color: white;
  padding: 5rem;
}
