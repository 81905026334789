.title {
  text-align: center;
  font-size: 20px;
}

.title h1 {
  font-size: 5rem;
  margin-top: 3rem;
  margin-bottom: 0;
}

.form {
  padding: 5rem;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  border: black 2px solid;
  gap: 4rem;
}

.form img {
  width: 50%;
  height: auto;
}

.form_container {
  display: flex;
  flex-direction: column;
}

.form_element label {
  font-size: 1.5rem;
  display: block;
  margin-bottom: 5px;
}

.form_element input {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}

.form_container button {
  width: 30em;
  height: 4em;
  color: white;
  background-color: rgba(51, 106, 134, 255);
  border: none;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 20px;
}

.form_container button:hover {
  background-color: rgba(51, 106, 134, 0.8);
}

@media (max-width: 1250px) {
  .form img {
    width: 80vw;
  }
}
